.skipNav,
.hide {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.cancelHighlight a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.hd-img {
  display: block;
  overflow: hidden;
  color: transparent;
  white-space: nowrap;
  background: url(../../img/set_hd.png) no-repeat;
  background-size: 75px auto;
}

/* header */
#header {
  overflow: hidden;
}
#header .gnbWrap {
  position: relative;
  width: 100%;
  height: 122px;
  z-index: 1;
  border-bottom: 1px solid #ececec;
}
#header .gnb {
  float: left;
}
#header .gnb a {
  display: block;
  color: #222;
}
#header .gnb .nate {
  float: left;
  width: 52px;
  height: 58px;
  padding: 19px 0 0 20px;
  box-sizing: border-box;
}
#header .gnb .nate .hd-img {
  width: 20px;
  height: 19px;
  background-position: 0 0;
}
#header .gnb .service {
  position: relative;
  top: 16px;
  float: left;
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.05em;
}
#header .relsvc {
  float: left;
  margin-left: 16px;
}
#header .relsvc a {
  float: left;
  margin: 0 10px 0 0;
  font-size: 16px;
  line-height: 58px;
}
#header .relsvc .vote {
  color: #4b43b5;
}
#header .searchWrap {
  width: 100%;
  margin: 2px 0;
  height: 44px;
  overflow: hidden;
}
#header .searchWrap #search-field {
  position: relative;
  margin: 0 15px;
  height: 44px;
  border: 1px solid #d1d1d1;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  z-index: 2;
}
#header .searchWrap #search-field fieldset {
  height: 24px;
  padding: 7px 75px 11px 15px;
}
#header .searchWrap #search-field .srchbtn {
  position: absolute;
  top: 0;
  right: 7px;
  width: 40px;
  height: 40px;
  border: 0;
  outline: none;
  background: none;
  z-index: 10;
}
#header .searchWrap #search-field .srchbtn .hd-img {
  display: block;
  width: 24px;
  height: 24px;
  margin: 10px;
  background-position: -25px -25px;
}
#header .searchWrap.on #search-field {
  border: 1px solid #ff2c2e;
}
#header .searchWrap.on #search-field .srchbtn .hd-img {
  background-position: -25px 0;
}
#header .searchWrap #search-field .delete {
  position: absolute;
  top: 10px;
  right: 43px;
  display: none;
  width: 24px;
  height: 24px;
  border: 0;
  outline: 0;
  background: none;
  padding: 5px;
}
#header .searchWrap #search-field .delete .hd-img {
  width: 14px;
  height: 14px;
  background-position: -50px 0;
}
#header .searchWrap #search-field input[type="search"] {
  position: relative;
  width: 100%;
  height: 27px;
  font-size: 20px;
  border: 0;
  background: none;
  outline: none;
  font-weight: 500;
}
#header .searchWrap #search-field input[type="search"]::-webkit-input-placeholder {
  font-size: 20px;
  color: #999;
}
#header .searchWrap #search-field input[type="search"]::-webkit-search-decoration,
#header .searchWrap #search-field input[type="search"]::-webkit-search-cancel-button,
#header .searchWrap #search-field input[type="search"]::-webkit-search-results-button,
#header .searchWrap #search-field input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

#header .searchWrap #search-field .hiddenInput {
  position:absolute;
  top:-1px;
  left:-1px;
  width:0;
  height:0;
  border:0;
  z-index: 1;
}
#header .lnbContainer {
  overflow: hidden;
}
#header .lnb {
  position: relative;
}
#header .lnb ul {
  position: relative;
  height: 48px;
  border-bottom: 1px solid #d2d2d2;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
#header .lnb ul li {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  flex: auto;
  text-align: center;
}
#header .lnb ul li a {
  position: relative;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  font-size: 17px;
  font-weight: 700;
  color: #000;
  letter-spacing: -0.05em;
}
#header .lnb ul li .on {
  font-weight: 700;
  color: #ff2c2e;
}
#header .lnb ul li .on::before {
  content: "";
  position: absolute;
  left: -2px;
  bottom: -1px;
  display: block;
  width: 100%;
  padding: 0 2px;
  height: 2px;
  background-color: #ff2c2e;
  z-index: 2;
}
#header .lnb_sub {
  border-bottom: 1px solid #ececec;
}
#header .lnb_sub ul {
  position: relative;
  height: 48px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
#header .lnb_sub ul li {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  flex: auto;
  text-align: center;
}
#header .lnb_sub ul li a {
  position: relative;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  letter-spacing: -0.05em;
  color: #666;
}
#header .lnb_sub ul li a.on {
  color: #ff2c2e;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .hd-img {
    background-image: url(../../img/set_hdx2.png);
  }
}

/* footer */
.footer { padding:30px 0 32px 0; background-color:#f8f8f8; font-weight:400; text-align:center; }
.footer a { position:relative; display:inline-block; letter-spacing: -0.5px; text-align:center; text-decoration: none; }
.footer a:before { content:''; position:absolute; top:3px; left:0; width: 1px; height: 12px; background-color:#e1e1e1; }
.safari .footer a:before { top:2px; }
.footer a:first-child:before { display:none; }
.footer .svcBtn { padding:2px 0 22px 0; font-size:0; line-height:0; white-space: nowrap; text-align: center; }
.footer .svcBtn a { padding:2px 9px; font-size:15px; line-height:18px; color:#101010; }
.footer .svcBtn a:before { top:5px; }
.safari .footer .svcBtn a:before { top:4px; }
.footer .copyright { font-size:0; line-height:0; white-space: nowrap; text-align: center; }
.footer .copyright span { display:inline-block; padding:2px 8px 0 0; font-size:12px; line-height:16px; letter-spacing:0; color:#888; }
.footer .copyright a { padding:2px 8px; font-size:12px; line-height:14px; color:#888; }
.footer .svcInfo { padding:2px 0 12px 0; font-size:0; line-height:0; white-space: nowrap; text-align: center; }
.footer .svcInfo a { padding:2px 8px; font-size:12px; line-height:14px; color:#888; }
.footer .svcInfo a.privacy { font-weight:700; }
.footer .svcInfo a + span { position:relative; display:inline-block; padding:2px 0 0 8px; font-size:12px; line-height:14px; color:#888; }
.footer .svcInfo a + span:before { content:''; position:absolute; top:3px; left:0; width: 1px; height: 12px; background-color:#e1e1e1; }

@media (prefers-color-scheme: dark) {
    :root {
      --bg-color1: #262626;
      --bg-color2: #121212;
      --bg-color3: #666;
      --font-color1: #fafafa;
      --font-color4: #777;
      --font-color5: #d7d7d7;
      --font-color6: #888;
      --grey-border-color: #464646;
      --grey-border-color2: #393939;
      --input-bg-color: #2f2f2f;
      --notice-border-color: #5a5a5a;
      --direct-font-color: #ebebeb;
      --direct-font-color2: #8f8f8f;
      --border-color3: #444;
    }
    #header {
      background-color: var(--bg-color1);
    }
    #header .gnb a {
      color: var(--font-color1);
    }
    #header .gnbWrap {
      border-bottom-color: var(--grey-border-color);
    }
    #header .searchWrap #search-field {
      border-color: var(--font-color4);
    }
    #header .searchWrap #search-field input[type="search"] {
      color: var(--font-color1);
    }
    #header .searchWrap #search-field input[type="search"]::-webkit-input-placeholder {
      color: var(--font-color4);
    }
    #header .searchWrap #search-field .srchbtn .hd-img {
      background-image: url(../../img/icon_search_darkx2.png);
      background-size: 24px 24px;
      background-position: center;
    }
    .noticeBox {
      border-bottom-color: var(--notice-border-color);
      background-color: var(--bg-color1);
    }
    .noticeBox a.txt {
      color: var(--font-color4);
    }
    .noticeBox a.txt em {
      color: var(--font-color4);
    }
    .noticeBox a.more {
      color: var(--font-color4);
    }
    .noticeBox a.more:before {
      background-image: url(../../img/btn_arrow_darkx2.png);
      background-size: 100%;
    }
    .directMenu {
      background-color: var(--input-bg-color);
    }
    .drWrap .pnm.v2 .pnmList li a {
      color: var(--direct-font-color);
    }
    .svcWrap {
      background-color: var(--bg-color1);
    }
    .selectType {
      margin: 0;
      padding: 20px 24px;
    }
    .selectType a {
      background-image: url(../../img/icon_checkx2.png);
      background-size: 14px 12px;
      color: var(--direct-font-color2);
    }
    .selectType a.crnt {
      color: var(--font-color1);
    }
    .svcList {
      margin: 0 9px;
      padding-bottom: 20px;
    }
    .svcList li .svcname {
      color: var(--font-color4);
    }
  #footer {
    border-top-color: var(--border-color3);
    background-color: var(--bg-color2);
  }
  #footer .foot_wrap {
    border-top: 0;
  }
  #footer button {
    border-color: var(--grey-border-color2);
    background-color: var(--input-bg-color);
    color: var(--font-color5);
  }
  #footer p.privacy a {
    color: var(--font-color6);
  }
  #footer p span.bar {
    color: var(--bg-color3);
  }
  #footer p.copyright {
    color: var(--font-color6);
  }
  .allView .alist li a {
    color: var(--font-color1);
  }
}
