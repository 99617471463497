body {
  line-height: 1em; /*1.2em;*/
  font-size:14px;
  -webkit-text-size-adjust: none;
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Apple SD Gothic Neo,Arial,sans-serif;
}
html {
  margin: 0;
}
.rest {
  font-family: "맑은 고딕", "Malgun Gothic", "돋움", Dotum, "굴림", Gulim, Helvetica, sans-serif;
}
.safari {
  font-family: HelveticaNeue,AppleSDGothicNeo-Regular,Arial,sans-serif;
  font-weight: 400;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
button,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
body,
input,
textarea,
select,
button,
table,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
img,
fieldset {
  border: 0;
}
ul,
ol,
li {
  list-style: none;
}
a {
  color: #222;
}
em,
i,
address {
  font-style: normal;
  font-weight: normal;
}
legend,
caption {
  clear: both;
  display: none;
}
input,
textarea,
select,
button,
table {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
table {
  border-collapse: collapse;
}
textarea {
  resize: none;
}
button,
label {
  cursor: pointer;
}
a,
a:hover,
a:active {
  text-decoration: none;
}
.safari strong {
  font-weight: 500;
}
input,
textarea,
button,
select {
  outline: none;
}
input,
textarea,
button {
  -webkit-border-radius: 0;
  border-radius: 0;
}
input,
textarea,
button {
  outline: none;
}
input[type="button"],
input[type="text"],
input[type="image"],
input[type="submit"],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
input[type="radio"] {
  -webkit-appearance: radio;
}
.type-text {
  background: #fff;
  height: 14px;
  text-align: left;
}
.type-btn {
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
  text-indent: -1000em;
  cursor: pointer;
  cursor: hand;
}
.f_clear:after {
  content: "";
  height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
* html .f_clear {
  height: 1px;
}
.f_clear {
  display: block;
  min-height: 1px;
}