/* main */
.noticeBox {
  position: relative;
  height: 36px;
  line-height: 36px;
  border-bottom: 1px solid #dcdcdc;
  overflow: hidden;
}
.noticeBox a.txt {
  display: block;
  padding: 0 87px 0 15px;
  color: #999;
  letter-spacing: -0.56px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.noticeBox a.txt em {
  color: #333;
}
.noticeBox a.more {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 0 20px 0 0;
  letter-spacing: -0.56px;
  color: #999;
}
.noticeBox a.more:before {
  content: "";
  position: absolute;
  top: 11px;
  right: 8px;
  width: 8px;
  height: 15px;
  background: url("../../img/ico_more.png") 0 0 no-repeat;
  background-size: 8px 15px;
}
.directMenu {
  clear: both;
  background-color: #f7f7f7;
}
.drWrap {
  padding: 20px 20px 5px 20px;
  overflow: hidden;
  position: relative;
}
.drWrap .pnm {
  margin-bottom: 96px;
}
.drWrap .pnm h3 {
  font-weight: bold;
  color: #373737;
}
.drWrap .pnm .pnmList {
  margin-top: 12px;
}
.drWrap .pnm .pnmList li {
  float: left;
  width: 50%;
  position: relative;
}
.drWrap .pnm .pnmList li a {
  display: block;
  height: 28px;
  line-height: 28px;
  color: #535353;
}
.drWrap .pnm .pnmList li .ex_menu {
  position: absolute;
  top: -30px;
}
.drWrap .pnm.v2 {
  margin-bottom: 15px;
  line-height: 1;
}
.drWrap .pnm.v2 .pnmList {
  margin-top: 0;
  font-size: 0;
}
.drWrap .pnm.v2 .pnmList li {
  float: none;
  width: 50%;
  position: relative;
  display: inline-block;
}
.drWrap .pnm.v2 .pnmList li a {
  height: auto;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.98px;
  text-align: left;
  color: #373737;
}
//.drWrap a.unfold {
//  position: absolute;
//  bottom: 20px;
//  right: 20px;
//  display: block;
//  width: 15px;
//  height: 15px;
//  border: 1px solid #cfcfcf;
//  background: url("../../img/icon_ctoggle.gif") 4px 6px #fff no-repeat;
//  font-size: 0;
//  line-height: 0;
//  text-indent: -1000em;
//  overflow: hidden;
//}
//.drWrap a.fold {
//  position: absolute;
//  bottom: 20px;
//  right: 20px;
//  display: block;
//  width: 15px;
//  height: 15px;
//  border: 1px solid #cfcfcf;
//  background: url("../../img/icon_ctoggle.gif") 4px -24px #fff no-repeat;
//  font-size: 0;
//  line-height: 0;
//  text-indent: -1000em;
//  overflow: hidden;
//}
@media all and (min-width: 640px) {
  .drWrap .pnm .pnmList li {
    width: 25%;
  }
}

.svcWrap {
  clear: both;
}
.selectType {
  margin: 30px 24px 20px;
  overflow: hidden;
  text-align: right;
}
.selectType span {
  padding: 0 5px;
  color: #d9d9d9;
}
.selectType a {
  display: inline-block;
  height: 20px;
  margin-left: 15px;
  padding-left: 18px;
  line-height: 20px;
  font-size: 13px;
  letter-spacing: -0.91px;
  color: #8f8f8f;
  background: url("../../img/ico_chk.png") 0 40% no-repeat;
  background-size: 11px 11px;
}
.selectType a.crnt {
  color: #000;
}
.svcList {
  overflow: hidden;
  margin: 0 9px 20px;
}
.svcList li {
  position: relative;
  float: left;
  width: 25%;
  height: 113px;
  text-align: center;
}
.svcList li .svcbtn {
  position: relative;
  display: block;
  width: 55px;
  margin: 0 auto;
  border: 0;
  background-color: transparent;
  z-index: 1;
}
.svcList li .svcbtn .icon {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background: url("../../img/set_my7.png") 0 0 no-repeat;
  background-size: 200px 515px;
}
.svcList li .svcbtn .icon.nateapp {
  background-position: 0 -150px;
}
.svcList li .svcbtn .icon.natemail {
  background-position: -50px -150px;
}
.svcList li .svcbtn .icon.comic {
  background-position: 0 -100px;
}
.svcList li .svcbtn .icon.uc {
  background-position: -150px -150px;
}
.svcList li .svcbtn .icon.cymera {
  background-position: 0 -415px;
}
.svcList li .svcbtn .icon.pannapp {
  background-position: 0 -465px;
}
.svcList li .svcbtn .icon.mail {
  background-position: 0 0;
}
.svcList li .svcbtn .icon.news {
  background-position: -50px 0;
}
.svcList li .svcbtn .icon.pann {
  background-position: -100px 0;
}
.svcList li .svcbtn .icon.wth {
  background-position: -150px 0;
}
.svcList li .svcbtn .icon.stylesvc {
  background-position: 0 -50px;
}
.svcList li .svcbtn .icon.ft {
  background-position: -50px -50px;
}
.svcList li .svcbtn .icon.video {
  background-position: -100px -50px;
}
.svcList li .svcbtn .icon.mobile {
  background-position: -150px -50px;
}
.svcList li .svcbtn .icon.comics {
  background-position: 0 -100px;
}
.svcList li .svcbtn .icon.movie {
  background-position: -50px -100px;
}
.svcList li .svcbtn .icon.note {
  background-position: 0 -200px;
}
.svcList li .svcbtn .icon.dotori {
  background-position: -50px -200px;
}
.svcList li .svcbtn .icon.nateq {
  background-position: -100px -100px;
}
.svcList li .svcbtn .icon.shp {
  background-position: -150px -100px;
}
.svcList li .svcbtn .icon.happ {
  background-position: -100px -200px;
}
.svcList li .svcbtn .icon.tv {
  background-position: 0 -250px;
}
.svcList li .svcbtn .icon.toonnbook {
  background-position: -100px -250px;
}
.svcList li .svcbtn .icon.ssul {
  background-position: -150px -250px;
}
.svcList li .svcbtn .icon.memInfo {
  background: url("../../img/ico_m_svc.png") 0 0 no-repeat;
  background-size: 100px 50px;
}
.svcList li .svcbtn .icon.privacyInfo {
  background: url("../../img/ico_m_svc.png") -50px 0 no-repeat;
  background-size: 100px 50px;
}
//.svcList li .svcbtn .icon.srch {
//  background: url("../../img/ico_m_svc_v3.png") -100px 0 no-repeat;
//  background-size: 150px 50px;
//}
.svcList li .svcname {
  display: block;
  margin: 6px auto 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.84px;
  text-align: center;
  color: #999;
}
@media all and (min-width: 640px) {
  .svcList li {
    width: 20%;
  }
}
.allView {
  position: relative;
  padding: 0 23px;
  overflow: hidden;
}
.allView .allBox {
  float: left;
}
.allView .alist {
  margin-bottom: 35px;
}
.allView .alist .tit {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #5a9cd1;
}
.allView .alist li {
  display: inline-block;
  margin-right: 30px;
}
.allView .alist li a {
  display: inline-block;
  height: 30px;
  letter-spacing: -0.98px;
  line-height: 30px;
  color: #000;
}
@media all and (min-width: 640px) {
  .allView .alist {
    float: left;
    width: 50%;
  }
}
/* button common */
.btnSend {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 30px 0;
  text-align: center;
}
.btnSend a {
  position: relative;
  width: calc(100% / 3);
  flex:1;
  align-items: center;
  height: 50px;
  font-size:16px;
  letter-spacing: -0.64px;
  color:#222;
  line-height:50px;
  border-radius: 3px;
  border: solid 1px #ccc;
  text-align: center;
}
.btnSend a:only-of-type {
  font-weight: bold;
}
.btnSend a + a {
  margin:0 0 0 15px;
}
.btnSend a.red {
  font-weight: normal;
  color: #fff;
  background-color:#ff2c2e;
}

.btnBox {
  position: relative;
  padding: 20px 0 60px 0;
  border-top: 1px solid #eee;
}
.btnBox ul {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 -4px;
}
.btnBox ul li {
  position: relative;
  width: calc(100% / 3);
  flex:1;
  display: inline-block;
  align-items: center;
  margin:0 4px;
}
.btnBox ul li a {
  display:block;
  height:36px;
  line-height:36px;
  letter-spacing: -0.56px;
  text-align: center;
  color: #333;
  border-radius: 3px;
  border: solid 1px #dbdbdb;
}

.btnWide {
  display: block;
  height: 50px;
  padding: 8px 0 0;
  border: 1px solid #bbb;
  margin: 15px 10px 0;
  background: #fff;
  text-align: center;
  color: #676767;
}
.btnMore {
  margin:-10px 20px;
}
.btnMore span {
  margin:0 20px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.56px;
  color: #333;
  background: url("../../img/icon_more.gif") 100% 50% no-repeat;
}
.btnList {
  margin:-22px 0 -10px 0;
}

/* paging */
.pg {
  height: 38px;
  margin: 20px -20px;
  font-size: 0;
  text-align: center;
}
.pg a,
.pg strong {
  display: inline-block;
  width: 38px;
  height: 38px;
  margin:0 4px;
  line-height:38px;
  font-size: 16px;
  text-align: center;
  border: solid 1px #dbdbdb;
  letter-spacing: -0.7px;
  color:#222;
}
.pg strong {
  background-color:#ff2c2e;
  border: 1px solid #ff2c2e;
  color: #fff;
  font-weight: normal;
}

.pg strong {
  background-color:#ff2c2e;
  border: 1px solid #ff2c2e;
  color: #fff;
  font-weight: normal;
}

.pg .btn_prev {
  margin:0 4px 0 0;
  color:transparent;
  background:#f9f9f9 url(../../img/btn_prev-2x.png) 13px 9px no-repeat;
  background-size:12px 20px;
}

.pg .btn_next {
  margin:0 0 0 4px;
  color:transparent;
  background:#f9f9f9 url(../../img/btn_next-2x.png) 13px 9px no-repeat;
  background-size:12px 20px;
}

/* sub contents */
.section {
  position: relative;
  padding:0 20px 0 20px;
  overflow: hidden;
}
.sectionList {
  position: relative;
  padding:0 20px 0 20px;
}
.sectionList::after {
  content:'';
  clear:both;
  display: block;
}

.snb {
  position:relative;
  margin:29px 0 0 0;
}
.snb h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.96px;
  text-align: left;
  color: #222;
  > a {
    color: #222;
  }
  &.svc-title {
    padding-right:127px;
  }
}
.snb button {
  position: absolute;
  bottom:-2px;
  right: 0;
  display: block;
  height: 32px;
  padding:0 30px 0 12px;
  background-color: #fafafa;
  border-radius: 3px;
  border: solid 1px #dbdbdb;
  box-sizing: border-box;
}
.snb button span {
  position:relative;
  display:block;
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
  &:before {
    content:'';
    position:absolute;
    top:-2px;
    right:-24px;
    width:20px;
    height:20px;
    background-image: url(../../img/bt_select.png);
    background-size: 20px 20px;
  }
}

/* list */
.hList {
  margin: 12px -20px 0;
  border-top:1px solid #666;
}
.hList li {
  position: relative;
  margin: 0 20px;
  padding: 16px 0;
  border-bottom: 1px solid #ededed;
  overflow: hidden;
  &:last-child {
    border-bottom:0;
  }
}
.hList li a {
  font-size: 14px;
  line-height: 1.47;
  letter-spacing: -0.7px;
  color:#666;
}
/* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;*/
.hList li a .ct1 {
  padding:0 4px 0 0;
  color: #999;
}
.hList li a .ct2 {
  padding:0 4px 0 0;
  color: #222;
}
//.locBar {
//  position: relative;
//  top: -2px;
//  height: 18px;
//  padding: 6px 0 0 11px;
//  background: #9d9ea2 url("../../img/bg_locbar.gif") repeat-x;
//  border-bottom: 1px solid #898a92;
//  font-size: 12px;
//  color: #ddd;
//  letter-spacing: -1px;
//}
//.locBar em {
//  color: #fff;
//}
.hView {
  margin:12px 0 0 0;
}
.hView .con dt {
  margin:0 -20px;
  padding: 24px 20px 20px;
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: -1.32px;
  color: #222;
  word-break: break-all;
  border-top:1px solid #666;
  & > .date {
    margin:10px 0 0 0;
    display:block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: -0.7px;
    color: #999;
  }
}
.hView .con dd {
  padding: 24px 0 40px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.56px;
  color: #666;
  word-break: break-all;
  border-top:1px solid #ececec;
}
.pnList {
  position: relative;
}
.pnList li {
  position: relative;
  display:flex;
  padding:16px 0;
  border-top: 1px solid #ececec;
}
.pnList li > span {
  position:relative;
  flex-shrink: 0;
  padding: 0 21px 0 0;
  color: #666;
}

.pnList .prev:before {
  content:'';
  position:absolute;
  top:-1px;
  right:8px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid #999;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.pnList .next:before {
  content:'';
  position:absolute;
  top:5px;
  right:8px;
  width: 0;
  height: 0;
  border-top: 6px solid #999;
  border-bottom: 6px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.pnList li .link {
  position:relative;
  flex-grow: 1;
  padding: 0 20px 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: -0.56px;
  color: #222;
  box-sizing: border-box;
  &:before {
    content:'';
    position:absolute;
    top:2px;
    left:0;
    display:block;
    width:1px;
    height:11px;
    background-color:#ccc;
  }
}

.srchWord {
  margin: 0 -20px 0;
  padding: 13px 20px;
  line-height: 1.43;
  letter-spacing: -0.56px;
  color:#666;
  word-break: break-all;
  word-wrap: break-word;
  background-color:#ececec;
  box-sizing: border-box;
  strong {
    color:#222;
  }
  em {
    font-weight: bold;
    color:#ff2c2e;
  }
}
.sList {
  margin:0 -20px;
}
.sList li {
  margin:0 20px;
  padding: 16px 0;
  letter-spacing: -1px;
  line-height: 1.3;
  border-bottom: 1px solid #ededed;
}
.sList li a {
  line-height: 1.47;
  color:#666;
}
.sList li .ct1 {
  padding-right:4px;
  color: #999;
}
.sList li .ct2 {
  padding-right:4px;
  color: #222;
}
.sList li .keyword {
  color: #ff2c2e;
  font-weight: normal;
}
.sList .null {
  padding: 55px 15px 55px;
  font-size: 16px;
  color: #333;
  line-height: 1.3;
  text-align: center;
  word-break: break-all;
  word-wrap: break-word;
}
.sList .null em {
  color: #f93a2d;
}
/* 공지사항 리스트 */
.nList {
  margin:12px -20px 0;
  border-top:1px solid #666;
}
.nList ul {
  margin:0 20px;
}
.nList li {
  position: relative;
  padding:14px 0;
  font-size: 15px;
  border-bottom: 1px solid #ededed;
}
.nList li a {
  display: block;
  width:100%;
  line-height: 1.33;
  letter-spacing: -1px;
  color: #222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nList li .date {
  display:block;
  margin:4px 0 0 0;
  line-height: 1.33;
  letter-spacing: -0.7px;
  color: #999;
}
.nView .con dt {
  border-bottom: 1px solid #ddd;
  padding: 15px 10px 7px;
  font-size: 16px;
  color: #000;
  line-height: 1.2;
  word-break: break-all;
}
.nView .con dt em {
  display: block;
  margin: 4px 0 2px;
  font-size: 12px;
  color: #aaa;
  letter-spacing: 0;
}

.nView .con dd {
  padding: 13px 10px 21px;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  word-break: break-all;
}
.nView .con .point {
  color: #f42f21;
}
.msgOK {
  margin:15px 0 0 0;
  padding: 82px 0 50px;
  text-align: center;
  border-top:1px solid #eee;
  border-bottom:1px solid #eee;
}
.msgOK span {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -1.08px;
  text-align: center;
  color: #222;
  &:before {
    content:'';
    position:absolute;
    top:-32px;
    left:50%;
    margin-left:-12px;
    width:24px;
    height:24px;
    background:url('../../img/icon_check01.png') 0 0 no-repeat;
    background-size:24px 24px;
  }
}
.msgOK span + span {
  margin:8px 0 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: -0.56px;
  color: #999;
  &:before {
    display:none;
  }
}

.msgWarning {
  margin:15px 0 0 0;
  padding: 58px 0 20px;
  text-align: center;
  border-top:1px solid #eee;
  border-bottom:1px solid #eee;
}

.msgWarning span {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -1.08px;
  text-align: center;
  color: #222;
  &:before {
    content:'';
    position:absolute;
    top:-36px;
    left:50%;
    margin-left:-12px;
    width:24px;
    height:24px;
    background:url('../../img/icon_warning.png') 0 0 no-repeat;
    background-size:24px 24px;
  }
  & + span {
    display:block;
    margin:12px 0 0 0;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: -0.6px;
    color: #777;
    &:before {
      display:none;
    }
  }
}

.msgWarning ul {
  margin:20px 0 0 0;
  padding: 20px;
  text-align: left;
  border-radius: 3px;
  border: solid 1px #eee;
  li {
    line-height: 1.29;
    letter-spacing: -0.56px;
    color: #777;
  }
}

.inQuiry {
  width: 100%;
  overflow: hidden;
}

.inquiryBox .login_area {
  position: relative;
  padding: 15px 0 20px;
  border-bottom: 1px solid #eee;
}
.inquiryBox .login_area .logout {

}
.inquiryBox .login_area .logout p {
  display:block;
  margin:0 0 15px 0;
  line-height: 18px;
  letter-spacing: -0.56px;
  color:#777;
}

.inquiryBox .login_area .login_user {
  margin:15px 0 0 0;
  font-size: 14px;
  color: #333;
  //line-height: 25px;
}
.inquiryBox .login_area .login_user strong {
  display: block;
  margin:0 0 12px 0;
  letter-spacing: -0.56px;
  text-align: left;
  color: #222;
}
.inquiryBox .login_area .login_user span {
  display: block;
  font-size: 18px;
  letter-spacing: -0.72px;
  text-align: left;
  color: #5c7bbf;
}

.inquiryBox .cate_list {
  padding: 24px 0 0 0;
}
.inquiryBox .cate_list h4 {
  display:block;
  margin:0 0 12px 0;
  letter-spacing: -0.56px;
  color: #222;
}
//.inquiryBox .cate_list ul {
//  overflow: hidden;
//  width: 75%;
//}
//.inquiryBox .cate_list ul li {
//  float: left;
//  color: #777;
//  margin-right: 10px;
//}
//.inquiryBox .cate_list ul li.cate1 {
//  text-align: left;
//}
//.inquiryBox .cate_list ul li.cate2 {
//  width: 33.4%;
//  text-align: left;
//}
//.inquiryBox .cate_list ul li.cate3 {
//  text-align: right;
//}
//.inquiryBox .cate_list ul li label {
//  padding-left: 5px;
//}

.inquiryBox .service_select {
  overflow: hidden;
}
.inquiryBox .service_select h4 {
  float: left;
  width: 25%;
  font-weight: bold;
  color: #343434;
  line-height: 27px;
  height: 27px;
}
.inquiryBox .service_select label {
  float: left;
  width: 75%;
}
.inquiryBox select {
  width: 100%;
  height: 50px;
  padding:0 0 0 12px;
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  background-color: #fafafa;
  background-image: url(../../img/bt_select.png);
  background-position: calc(100% - 10px) 15px;
  background-repeat: no-repeat;
  background-size:20px 20px;
  -webkit-appearance: none;
  box-sizing: border-box;
}
.inquiryBox input[type="text"], .inquiryBox input[type="email"]  {
  width: 100%;
  height: 50px;
  padding:0 0 0 12px;
  font-size: 16px;
  color: #222;
  border-radius: 3px;
  border: solid 1px #dbdbdb;
  background-color: #fafafa;
  box-sizing: border-box;
}
.inquiryBox input[type="text"]::-webkit-input-placeholder {
  color: #777;
}
.inquiryBox input[type="text"]:focus, .inquiryBox input[type="email"]:focus {
  border:1px solid #72778b;
}
.inquiryBox .mobile_info {
  overflow: hidden;
  padding: 30px 0 0 0;
}
.inquiryBox .mobile_info h4 {
  display: inline-block;
  margin:0 0 12px 0;
  letter-spacing: -0.56px;
  color: #222;
}
.inquiryBox .mobile_info span.userAgent {
  display: inline-block;
  font-size: 13px;
  letter-spacing: -0.52px;
  color: #777;
}
.inquiryBox .mobile_info ul li {
  width:100%;
}
.inquiryBox .mobile_info ul li:nth-of-type(2) {
  display:none;
}
.inquiryBox .mobile_info ul.etc li:nth-of-type(2) {
  display: block;
  margin:8px 0 0 0;
}
.inquiryBox .mobile_info .emailId {
  display: block;
  margin-top:-2px;
  font-size: 18px;
  line-height:18px;
  letter-spacing: -.72px;
  text-align: left;
  color: #5c7bbf;
}
.inquiryBox .mobile_info .emailId + p {
  display:block;
  margin:10px 0 0 0;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.52px;
  color: #777;
}

.inquiryBox .mobile_info .noti {

}

.inquiryBox .counsel {

}
.inquiryBox .counsel textarea {
  width: 100%;
  height: 260px;
  padding:16px 12px 0;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.64px;
  color:#222;
  border-radius: 3px;
  border: solid 1px #dbdbdb;
  background-color: #fafafa;
  box-sizing: border-box;
}
.inquiryBox .counsel p {
  padding-top: 15px;
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #777;
  text-align: right;
}
.inquiryBox .counsel p strong {
  color: #222;
}
.inquiryBox .answer {
  margin:15px 0 0 0;
}
.inquiryBox .answer p {
  line-height: 1.29;
  letter-spacing: -0.56px;
  color: #777;
}

.inquiryBox .txt_info {
  margin:20px 0 0 0;
  padding: 20px 0 10px 0;
  border-top:1px solid #eee;
}
.inquiryBox .txt_info p {
  line-height: 1.29;
  letter-spacing: -0.56px;
  color: #777;
}
.inquiryBox .txt_info p strong {
  font-weight: bold;
  color: #222;
}
.inquiryBox .txt_info p + p {
  margin:8px 0 0 0;
}
.inquiryBox .txt_info label {
  padding-left: 5px;
}

.inquiryBox .txt_info .chkbox {
  position: relative;
  display: block;
  height: 20px;
  margin:20px 0 0 0;
}
.inquiryBox .txt_info .chkbox .chk-ipt {
  position: absolute;
  top: 1px;
  left: 2px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.inquiryBox .txt_info .chkbox .chk-label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 13px;
  line-height: 20px;
  height: 20px;
  padding-left: 28px;
  cursor: pointer;
  letter-spacing: -0.52px;
  color: #777;
  border: 1px solid #fff;
  background-color: #fff;
}
.inquiryBox .txt_info .chkbox .chk-label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-image: url(../../img/img_checkbox_off.png);
  background-size:20px 20px;
}

.inquiryBox .txt_info .chkbox .chk-ipt:checked + label:before, .inquiryBox .txt_info .chkbox .chk-ipt:checked + label.on:before {
  background-image: url(../../img/img_checkbox_on.png);
  background-size:20px 20px;
  width: 20px;
  height: 20px;
}

.telInfo {
  padding:0 0 20px 0;
}
.telInfo h4 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.64px;
  text-align: left;
  color: #222;
}
.telInfo .info {
  margin: 15px 0 0 0;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 3px;
}
.telInfo .info+.info {
  margin:12px 0 0 0;
}
.telInfo ul {
  margin-top: 14px;
}
.telInfo ul li {
  position: relative;
  margin:0 0 6px 0;
  padding-left: 12px;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.6px;
  color: #666;
}
.telInfo ul li:last-child {
  margin:0;
}
.telInfo ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 4px;
  height: 4px;
  background: #bfc2cb;
  border-radius: 50%;
}
.telInfo ul li em {
  color: #ff2c2e;
}
.telInfo ul li.tel {
  padding-top: 12px;
}
.telInfo ul li.tel:before {
  display: none;
}
.telInfo ul li strong {
  position: relative;
  padding-left: 15px;
  font-weight: normal;
}
.telInfo ul li strong:before {
  content: "";
  position: absolute;
  left: 7px;
  top: 1px;
  width: 1px;
  height: 14px;
  background: #bfc2cb;
}
.telInfo .info dl {
  padding: 7px 0 10px;
}
.telInfo .info dd {
  padding: 2px 0 0;
  font-size: 13px;
}
.telInfo .info dd em {
  color: #f93a2d;
}
.telInfo .info dd i {
  color: #888;
}
.telInfo .btn {
  position: relative;
  width: 100%;
  padding: 24px 0 25px;
  text-align: center;
}
.telInfo .btn .mail {
  position: absolute;
  right: 0;
  bottom: 36px;
  color: #aaa;
  font-size: 14px;
  text-decoration: underline;
}

.lyrCate {
  position: absolute;
  top: -29px; //not overflow hidden
  left: 0;
  display:none;
  width: 100%;
  box-shadow: 0 8px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dbdbdb;
  background-color: #fafafa;
  z-index: 10;
}
.lyrCate.show {
  display:block;
}
.lyrBtn {
  height: 50px;
  border-top: 1px solid #dbdbdb;
  background: #f6f6f6;
}
.lyrBtn .close {
  display: block;
  text-align: center;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height:50px;
  font-size: 13px;
  color: #222;
}

.conCate {
  padding: 24px 20px 45px;
}
.conCate .cList {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.conCate .cList li {
  position: relative;
  width: 33.333333%;
  margin:0 0 20px 0;
}
.conCate .cList li a {
  display: block;
  padding:0 10px 0 0;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.6px;
  color: #222;
}

/* button */
.btn {
  padding-top: 20px;
  text-align: center;
}
.btn a:hover {
  text-decoration: none;
}
.btn_gray {
  width:100%;
  display: block;
  min-height: 50px;
  border: 1px solid #c9c9c9;
  background-color: #f6f6f6;
  vertical-align: middle;
}
.btn_red {
  display: block;
  width:100%;
  height: 50px;
  line-height:50px;
  background-color: #ff2c2e;
  border-radius: 3px;
  font-size: 16px;
  letter-spacing: -0.64px;
  color:#fff;
  text-align: center;
}

